/* eslint-disable i18next/no-literal-string */
import dynamic from "next/dynamic";
import { ReactNode } from "react";
import { useFeatureToggle } from "../../utils/hooks/featureToggles";

const DynamicAccessibilitySimulator = dynamic(() => import("./AccessibilitySimulator"));

export const FrokostseminarProvider = (props: { children: ReactNode }) => {
  const accessibilitySimulator = useFeatureToggle("uu");

  if (!accessibilitySimulator) return props.children;

  return <DynamicAccessibilitySimulator>{props.children}</DynamicAccessibilitySimulator>;
};
