import { useIsInsideAnIframe } from "@libry-content/common";
import { useSearchParams } from "next/navigation";
import { createContext, lazy, PropsWithChildren, Suspense, useContext, useEffect, useState } from "react";

const SanityLiveQueryProvider = lazy(() => import("./SanityLiveQueryProvider"));

type State = {
  isOn: boolean;
};

const initState: State = {
  isOn: false,
};

const Context = createContext<State>(initState);

export const usePreviewContext = () => useContext(Context);

export const PreviewContextProvider = (props: PropsWithChildren) => (
  <Suspense fallback={props.children}>
    <SuspendedPreviewContextProvider>{props.children}</SuspendedPreviewContextProvider>
  </Suspense>
);

export const SuspendedPreviewContextProvider = (props: PropsWithChildren) => {
  const searchParams = useSearchParams();
  const previewModeInUrl = !!searchParams?.get("preview");
  // Need to persist in state because queryparams are not perserved when navigating to a new page in the preview
  const [previewModePersisted, setPreviewModePersisted] = usePersistantState("libryContentPreviewMode", false);
  const isInsideIframe = useIsInsideAnIframe();

  useEffect(() => {
    if (previewModeInUrl) {
      setPreviewModePersisted(true);
    }
  }, [previewModeInUrl, setPreviewModePersisted]);

  const previewIsOn =
    !isInsideIframe.loading && isInsideIframe.isInside // Only enter preview-mode if we're inside an iframe to avoid entering preview-mode when navigating to the frontend from the studio. Checking if isInsideIframe is loading is to avoid hydration issues
      ? previewModeInUrl || previewModePersisted // State in url is available before state is set, so we use it if it exists to avoid flickering
      : false;

  useEffect(() => {
    previewIsOn === true && console.log("🔎 preview on"); // Å logge at vi er i preview-mode har vist seg å være nyttig mtp debugging, feks hvis man er i preview-mode uten å være klar over det selv
  }, [previewIsOn]);

  const contextProviderNode = <Context.Provider value={{ isOn: previewIsOn }}>{props.children}</Context.Provider>;

  if (!previewIsOn) return contextProviderNode;

  return <SanityLiveQueryProvider>{contextProviderNode}</SanityLiveQueryProvider>;
};

// We need to store state in session storage to share between app router and pages router (They don't mount the same instance of provdiers)
const usePersistantState = (key: string, initialValue: boolean) => {
  const [state, setState] = useState<boolean>(() => {
    if (typeof window === "undefined") return initialValue;
    const value = window.sessionStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  });

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState] as const;
};
