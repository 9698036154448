import { featureToggleEnabled, FeatureToggleKey, getEnabledFeatureToggles as getEnabledFeatureToggles } from "@libry-content/common";
import { useEffect, useState } from "react";

export const useEnabledFeatureToggles = () => {
  const [toggles, setToggles] = useState<string[]>([]);

  useEffect(() => {
    setToggles(getEnabledFeatureToggles());
  }, []);

  return toggles;
};

export const useFeatureToggle = (featureKey: FeatureToggleKey) => {
  const [enabled, setEnabled] = useState(false);

  // should be disabled on the first render cycle, so that the DOM tree will be the same as on the server
  // side, where URL query parameters are not available and all feature flags are effectively off.
  useEffect(() => {
    setEnabled(featureToggleEnabled(featureKey));
  }, [featureKey]);

  return enabled;
};
