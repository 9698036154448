import { sanityConfig } from "@libry-content/common";
import { createClient } from "next-sanity";

// Don't include token here, or client side fetches will include drafts
export const sanityClient = createClient({
  ...sanityConfig,
  useCdn: true,
});

// Sanity uses localStorage for authtoken when logging in with some browsers (ie. Firefox and safari), while for Chrome Sanity uses cookies
const getSanityAuthTokenFromLocalStorage = (): string | undefined => {
  if (typeof window === "undefined" || !localStorage) return undefined;

  const localStorageToken = localStorage.getItem(`__studio_auth_token_${sanityConfig.projectId}`);

  if (!localStorageToken) return undefined;

  try {
    return JSON.parse(localStorageToken)?.token;
  } catch (err) {
    console.error("Could not parse Sanity auth token from localStorage:", err);
    return undefined;
  }
};

// Client with credentials is used for preview mode
export const clientWithCredidentials = sanityClient.withConfig({
  token: getSanityAuthTokenFromLocalStorage(), // Needed for users that are logged in with token in local storage (ie. in Firefox)
  withCredentials: true, // Needed for users that are logged in with cookie (ie. in Chrome)
  useCdn: false, // will be set to false when withCredentials is true, but setting it explicitly removes warnings in console
});

export const getNextFetchRequestConfig = (siteDomain: string) => ({
  revalidate: 3600, // Skal bli revalidert ved publisering vha on-demand validation, men revaliderer data hver time i tillegg som fallback dersom on-demand validation skulle feile
  tags: [siteDomain], // Bruker siteDomain som cache-tag. Brukes for å kunne invalidere cachen for hele siten i "api/internal/invalidate-next-cache". Skjer ved publisering av innhold i sanity
});
