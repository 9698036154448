import { extendTheme } from "@biblioteksentralen/react";
import { Source_Sans_3 } from "next/font/google";

// Note that Source_Sans_Pro was renamed Source_Sans_3 https://github.com/vercel/next.js/pull/53270
const sourceSans = Source_Sans_3({
  weight: ["400", "600", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

// https://blog.logrocket.com/guide-css-word-wrap-overflow-wrap-word-break/
// https://medium.com/clear-left-thinking/all-you-need-to-know-about-hyphenation-in-css-2baee2d89179
// Skrur på hyphens auto som default fordi vi har masse mikrotekster og små elementer der layout knekker uten dette, og vi lager stadig nye og glemmer å ta hensyn til dette.
// Så kan man optionaly sette hyphens: none de stedene man ikke ønsker det, men har ikke sett noe som knekker/blir rart pga dette enda
const globalHyphens = {
  hyphens: "auto",
  hyphenateLimitChars: "8 5 3", // minimum word-length / min letters first line / min letters second line
};

const outlineFocus = {
  _focusVisible: {
    outline: "3px solid black",
    boxShadow: "0 0 0 6px white",
  },
};

export const customTheme = extendTheme({
  fonts: {
    body: sourceSans.style.fontFamily,
    heading: sourceSans.style.fontFamily,
  },
  styles: {
    global: {
      body: {
        ...globalHyphens,
      },
      button: outlineFocus,
      a: outlineFocus,
    },
  },
});
