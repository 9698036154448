"use client";

import { useToast } from "@biblioteksentralen/react";
import { isBibsentEmail, isGlobalAdmin } from "@libry-content/common";
import { useEffect } from "react";
import { useSanityAuth } from "../../components/sanityAuth/useSanityAuth";
import { MissingTranslationEvent, missingTranslationEventKey } from "../../i18n";

// This component listens for missing translations and shows a toast with the missing key when it happens. We've often discovered missing translations in production, so this is a way to catch them early.
export const MissingTranslationToaster = () => {
  const toast = useToast();
  const sanityAuth = useSanityAuth();

  useEffect(() => {
    // We only show missing translations toasts for admin-users
    if (!isBibsentEmail(sanityAuth.email) && !isGlobalAdmin(sanityAuth)) return;

    const handleMissingTranslation = (event: MissingTranslationEvent) => {
      const key = event.detail.key;
      !toast.isActive(key) &&
        toast({
          title: "Missing translation",
          description: key,
          status: "warning",
          duration: 30000,
          isClosable: true,
          id: key,
          position: "bottom-right",
        });
    };

    window.addEventListener(missingTranslationEventKey, handleMissingTranslation as EventListener);
    return () => window.removeEventListener(missingTranslationEventKey, handleMissingTranslation as EventListener);
  }, [toast, sanityAuth]);

  return null;
};
