interface Config {
  editMode: boolean;
}

const initalConfig: Config = {
  editMode: false,
};

const localStorageKey = "libry-content-local-config";

export class LocalConfig {
  static getConfig(): Config {
    try {
      const storedConfig = localStorage.getItem(localStorageKey);
      return storedConfig ? { ...initalConfig, ...JSON.parse(storedConfig) } : initalConfig;
    } catch {
      return initalConfig;
    }
  }

  static setConfig(change: Partial<Config>) {
    localStorage.setItem(localStorageKey, JSON.stringify({ ...LocalConfig.getConfig(), ...change }));
  }
}
