/* eslint i18next/no-literal-string: 0 */
import { Box, colors, Flex, FlexProps, HStack } from "@biblioteksentralen/react";
import { isProduction, isProductionDataset } from "@libry-content/common";
import { useEnabledFeatureToggles } from "../utils/hooks/featureToggles";
import { usePreviewContext } from "./preview/previewContext";

const dataset = process.env.SANITY_STUDIO_API_DATASET;

function Testenvironmentbanner() {
  // We assume that if we are inside an iframe, we are in the studio preview
  const preview = usePreviewContext();

  if (isProduction || preview.isOn) {
    return null;
  }

  if (isProductionDataset) {
    return (
      <Layout backgroundColor={colors.statusRed} fontSize="4xl">
        Dette er data fra produksjon, vær forsiktig!
      </Layout>
    );
  }

  return <Layout backgroundColor={colors.statusGreen}>Dette er et testmiljø ({dataset})</Layout>;
}

const Layout = ({ children, ...chakraProps }: { children: React.ReactNode } & FlexProps) => (
  <Flex
    color="white"
    padding="0.25rem"
    justifyContent="center"
    fontWeight="600"
    fontSize="0.7rem"
    gap=".5em 2em"
    flexWrap="wrap"
    sx={{
      "@media print": { display: "none" },
    }}
    {...chakraProps}
  >
    <Box>{children}</Box>
    <EnabledFeatureToggles />
  </Flex>
);

const EnabledFeatureToggles = () => {
  const enabledToggles = useEnabledFeatureToggles();

  if (!enabledToggles.length) return null;

  return (
    <HStack spacing=".5em" flexWrap="wrap">
      <Box>Funksjonsbrytere:</Box>
      <HStack flexWrap="wrap" divider={<Box border="none">•</Box>} as="span" fontWeight={600}>
        {enabledToggles.map((toggle) => (
          <Box key={toggle}>{toggle}</Box>
        ))}
      </HStack>
    </HStack>
  );
};

export default Testenvironmentbanner;
